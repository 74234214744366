import CTAButtons from '../shared/CTAButtons';
import React from 'react';
const TitleSection = () => {
  return (
    <div className="flex flex-col gap-[2.4375rem] justify-center items-center mt-[9.75rem] mobile:mt-[103px]">
      <div className="flex flex-col items-center justify-center gap-6">
        <h1 className="font-bold titleSections desktop:w-[986px] text-center desktop:text-[80px] tablet:text-[44px] mobile:text-[28px] tablet:max-w-[782px] mobile:max-w-[312px] leading-[80px] mobile:leading-[120%] text-offBlack">
          Join the Social Commerce Revolution
        </h1>
        <p className="font-normal text-[20px] mobile:text-[13px] mobile:leading-[150%] mobile:max-w-[255px] leading-8 text-offBlack opacity-75 max-w-[32.875rem] text-center">
          Supercharge your business with the power of Conversational AI
        </p>
      </div>
      <CTAButtons />
    </div>
  );
};

export default TitleSection;
