import PropTypes from 'prop-types';
import { leftData } from '../../data/Demos/LeftSideData';
import React from 'react';

const IntroComponent = ({ demoId }) => {
  const Data = leftData.find((data) => data.id === demoId) || {};

  const handleTryDemo = () => {
    const tryElement = document.getElementById('try');
    if (tryElement) {
      tryElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const handleBuildYourOwn = () => {
    window.location.href = '/join';
  };

  return (
      <div className="mt-8 flex items-center gap-[7.3125rem] relative mobile:flex-col-reverse tablet:flex-col mobile:justify-center">
        <video
            src={Data.videoUrl}
            autoPlay
            loop
            muted
            playsInline
            className="h-[567px] rounded-xl max-w-[318px] shadow-[0px_30.061px_91.951px_0px_rgba(18,22,28,0.10)]"
        />

        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <h2 className="title_blocs">{Data.title}</h2>
            <p className="para_blocs">{Data.description}</p>
          </div>
          <div className="flex gap-6">
            <button
                onClick={handleTryDemo}
                className="cta_btn flex-1 bg-white border border-Smoke whitespace-nowrap
              hover:bg-gray-50 transition-all duration-200
              active:transform active:scale-95
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
            >
              Try demo
            </button>
            <button
                onClick={handleBuildYourOwn}
                className="bg-background-button cta_btn flex-1 text-white whitespace-nowrap
              hover:opacity-90 transition-all duration-200
              active:transform active:scale-95
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Build Your Own
            </button>
          </div>
        </div>
        <div className="custom_shadow_orange" />
      </div>
  );
};

IntroComponent.propTypes = {
  demoId: PropTypes.string,
};

export default IntroComponent;