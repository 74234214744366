import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { leftData } from '../../data/Demos/LeftSideData';
import { createConsumer } from "@rails/actioncable";
import ProfileComponent from '../shared/ProfileComponent';

const ChatContent = ({ demoId }) => {
  const chosenData = leftData.find((data) => data.id === demoId);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const channelRef = useRef(null);
  const messagesEndRef = useRef(null);
  const clientIdRef = useRef(null);

  const scrollToBottom = () => {
    if(messages.length > 0){
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const connectToChannel = () => {
    if (channelRef.current) {
      channelRef.current.unsubscribe();
    }

    setIsConnected(false);
    clientIdRef.current = `${demoId}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const cable = createConsumer(`${window.location.protocol}//${window.location.host}/cable`);

    channelRef.current = cable.subscriptions.create(
        {
          channel: "DemoChannel",
          client_id: clientIdRef.current,
          demo_type: demoId
        },
        {
          connected() {
            setIsConnected(true);
            setMessages([]);
          },
          disconnected() {
            setIsConnected(false);
          },
          received(data) {
            setIsTyping(false);

            if (data.type === 'history') {
              setMessages(data.messages.map(msg => ({
                ...msg,
                id: msg.message_id
              })));
            } else {
              setMessages((prev) => [...prev, {
                message: data.message,
                error: data.error,
                id: "bot"
              }]);
            }
          }
        }
    );
  };

  useEffect(() => {
    connectToChannel();

    return () => {
      if (channelRef.current) {
        channelRef.current.unsubscribe();
        channelRef.current = null;
      }
    };
  }, [demoId]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (!input.trim() || !channelRef.current) return;

    setMessages((prev) => [...prev, {
      message: input.trim(),
      timestamp: new Date(),
      id: "user"
    }]);

    setIsTyping(true);

    try {
      channelRef.current.perform('speak', {
        message: input.trim(),
        client_id: clientIdRef.current,
        demo_type: demoId
      });
      setInput('');
    } catch (error) {
      setIsTyping(false);
    }
  };

  return (
      <div className="desktop:h-[32.3125rem] h-[38.3125rem] w-full border border-Smoke bg-white shadow-[0px_30.061px_91.951px_rgba(18,22,28,0.10)] flex flex-col my-6 rounded-2xl overflow-hidden ">
        <div className="border-b border-Smoke">
          <ProfileComponent data={chosenData} />
        </div>
        <div className="flex flex-col justify-between h-full desktop:py-6 desktop:px-4 py-3 px-2 relative ">
          <div className="flex flex-col desktop:gap-1 gap-3 desktop:max-h-[276px] max-h-[394px] overflow-y-scroll ">
            {messages.map((mess, i) => (
                <p
                    key={i}
                    className={`py-3 px-4 ${
                        mess.id === 'bot'
                            ? 'bg-[#EFEFEF] text-[#262626]'
                            : 'bg-[#2179EA] text-white ml-auto'
                    }   rounded-[20px] w-fit desktop:text-lg text-sm `}>

                  {mess.message}
                </p>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="flex items-center w-full relative cursor-pointer mt-4">
            <form onSubmit={sendMessage} className="flex w-full">
              <input
                  type="text"
                  placeholder="Or type to ask me anything..."
                  className="bg-[#EFEFEF] w-full py-2 px-4 rounded-full"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
              />
              <button className="ml-2 p-2 bg-Smoke rounded-full">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                >
                  <circle cx="16" cy="16" r="16" fill="#D9D9D9" />
                  <path
                      d="M13.481 19.7963L13.389 19.6425L13.2352 19.5504L7.29183 15.9946C7.09725 15.7408 7.21568 15.4023 7.47606 15.3104L22.3879 10.0443C22.7481 9.91707 23.1144 10.2834 22.9872 10.6436L17.7211 25.5554C17.6292 25.8158 17.2907 25.9342 17.0369 25.7397L13.481 19.7963Z"
                      stroke="#12141D"
                      strokeWidth="1.42633"
                  />
                  <path
                      d="M23.173 9.85876L13.0716 19.9602"
                      stroke="#12141D"
                      strokeWidth="1.42633"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
  );
};

ChatContent.propTypes = {
  demoId: PropTypes.string,
};

export default ChatContent;
