import React from 'react';
import { useNavigate } from 'react-router-dom';

const CTAButtons = () => {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/join');
    };

    const handleDemo = () => {
        navigate('/demos');
    };

    return (
        <div className="flex items-center gap-[1.4375rem] justify-center desktop:flex-row flex-col mobile:gap-[10px]">
            <button
                onClick={handleGetStarted}
                className="bg-background-button h-[60px] w-[207px] rounded-[4px] text-white
          hover:opacity-90 transition-all duration-200
          active:transform active:scale-95
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                Get Started Free
            </button>
            <button
                onClick={handleDemo}
                className="h-[60px] w-[206px] rounded-[10px] text-white bg-black font-bold
          hover:bg-gray-800 transition-all duration-200
          active:transform active:scale-95
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
                See demo
            </button>
        </div>
    );
};

export default CTAButtons;