import PropTypes from 'prop-types';
import { leftData } from '../../data/Demos/LeftSideData';
import ProfileComponent from '../shared/ProfileComponent';
import React from 'react';
const SideBareLeft = ({ setDemoId, demoId }) => {
  return (
    <div className="grid col-span-2 h-[calc(100vh-64px)] sticky top-14 place-content-center mobile:hidden">
      <div className="rounded-2xl border border-Smoke bg-frosted-lavender-end overflow-hidden ">
        {leftData.map((data, i) => (
          <ProfileComponent
            key={i}
            data={data}
            sidebar={true}
            setDemoId={setDemoId}
            demoId={demoId}
          />
        ))}
      </div>
    </div>
  );
};

SideBareLeft.propTypes = {
  setDemoId: PropTypes.func,
  demoId: PropTypes.string,
};

export default SideBareLeft;
