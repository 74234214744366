import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GetStarted from './components/GetStarted';
import { AuthProvider } from './context/AuthContext';
import App from './components/App';
import Header from './components/shared/Header.jsx';



document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(
        <AuthProvider>
            <Header />
            <App />
        </AuthProvider>
        , document.getElementById("root"));
});import "./channels"
