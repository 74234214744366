import { useState } from 'react';
import RightContent from '../components/Demos/RightContent';
import SideBareLeft from '../components/Demos/SideBareLeft';
import SideBareLeftMobile from '../components/Demos/SideBareLeftMobile';
import React from 'react';

const DemoPage = () => {
    const [demoId, setDemoId] = useState('fitness');

    const handleBuildYourOwn = (e) => {
        console.log('click');
        e.preventDefault(); // Prevent any default behavior
        window.location.href = '/join';
    };

    return (
        <div className="grid grid-cols-5 h-full w-full relative top-16 mobile:flex mobile:flex-col overflow-x-hidden">
            <SideBareLeft setDemoId={setDemoId} demoId={demoId} />
            <SideBareLeftMobile setDemoId={setDemoId} demoId={demoId} />
            <RightContent demoId={demoId} handleBuildYourOwn={handleBuildYourOwn} />
            <div className="custom_shadow_orange_mobile" />
            <div className="hidden mobile:block bg-white p-4 fixed bottom-0 w-full shadow-lg">
                <button
                    onClick={handleBuildYourOwn}
                    className="cta_btn text-white w-full bg-background-button
                        hover:opacity-90 transition-all duration-200
                        active:transform active:scale-95"
                >
                    Build Your Own For Free
                </button>
            </div>
        </div>
    );
};

export default DemoPage;