import React, { useState } from 'react';

const Playground = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [instructions, setInstructions] = useState(
        "LumintSkinCare AI Customer Service Agent: Standard Operating Procedure (SOP)\n" +
        "\n" +
        "1. Greeting and Introduction\n" +
        "Begin each conversation with a friendly greeting and introduce yourself as LumintSkinCare’s customer service agent.\n" +
        "Example: “Hello! 🌸 Welcome to LumintSkinCare. I’m here to help with anything you need!”\n" +
        "2. Understanding Customer Needs\n" +
        "Identify Intent: Listen for cues in customer questions to understand their specific needs (e.g., product inquiries, skincare concerns, order tracking).\n" +
        "Confirm Needs: If unclear, politely ask for more details.\n" +
        "Example: “I'd love to help! Are you looking for specific skincare products or have questions about your order?”\n" +
        "3. Product Recommendations\n" +
        "Ingredient & Skin Type Matching: Suggest products based on customer skin type or concerns (e.g., dryness, sensitivity).\n" +
        "Highlight Benefits: Mention that LumintSkinCare products are organic, use natural Asian ingredients, and avoid harsh chemicals.\n" +
        "Upsell Gently: If applicable, suggest complementary products.\n" +
        "Example: “Our Green Tea Hydration Serum is fantastic for dry skin! It’s packed with antioxidants and hydration. Would you like to hear about a matching moisturizer?”\n" +
        "4. Answering Frequently Asked Questions\n" +
        "Product Usage: Guide customers on how to use products effectively (e.g., order of application, frequency).\n" +
        "Order Tracking: If asked, direct customers to their account or provide a brief guide on tracking an order.\n" +
        "Return Policy: If returns are mentioned, provide a friendly summary of the policy.\n" +
        "Example: “If you’re unsatisfied, returns are accepted within 30 days. Just let me know if you need more details!”\n" +
        "5. Handling Complaints or Issues\n" +
        "Apologize & Empathize: Acknowledge any concerns and reassure the customer.\n" +
        "Provide Solutions: Suggest specific resolutions or direct them to the customer service team if the issue needs further help.\n" +
        "Example: “I’m so sorry to hear that! Let’s get this resolved. Would you like a replacement, or is there something else I can help with?”\n" +
        "6. Tone & Brand Voice\n" +
        "Friendly and Warm: Use positive, friendly language and emojis sparingly to convey warmth.\n" +
        "Educative but Concise: Be informative, but keep responses brief and focused.\n" +
        "Empathy for Concerns: Always validate the customer’s experience, especially with sensitive skin issues.\n" +
        "7. Closing the Conversation\n" +
        "Express Gratitude: Thank the customer for choosing LumintSkinCare.\n" +
        "Offer Further Assistance: Invite them to reach out again anytime.\n" +
        "Example: “Thanks so much for reaching out! 😊 If you need any more help, I’m just a message away. Have a wonderful day!”"
    );
    const [messages, setMessages] = useState([
        { id: 1, sender: 'user', text: 'Hi there! Can you help me learn more about your services?' },
        // { id: 2, sender: 'ai', text: 'Of course! I\'d be happy to help. What would you like to know?' },
        // { id: 3, sender: 'user', text: 'What kind of support do you offer?' },
        // { id: 4, sender: 'ai', text: 'We offer 24/7 customer support through multiple channels including chat, email, and phone. Our team is always ready to assist you with any questions or concerns you might have.' }
    ]);
    const [newMessage, setNewMessage] = useState('');

    const handleInstructionSave = () => {
        setIsEditing(false);
        // Add any save logic here
    };

    const startNewConversation = () => {
        setMessages([]);
        // Add any new conversation initialization logic here
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        const newMsg = {
            id: Date.now(),
            sender: 'user',
            text: newMessage.trim()
        };

        setMessages(prev => [...prev, newMsg]);
        setNewMessage('');

        // Simulate AI response
        setTimeout(() => {
            const aiResponse = {
                id: Date.now() + 1,
                sender: 'ai',
                text: 'Hi there! 🌸 Thanks for reaching out to LumintSkinCare! Our products are crafted with pure, organic ingredients from Asia for natural, high-quality skincare. Let me know if you need help finding the perfect match for your skin—I\'m here to help! 😊'
            };
            setMessages(prev => [...prev, aiResponse]);
        }, 1000);
    };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            {/* Header with title and Connect AI button */}
            <div className="max-w-7xl mx-auto mb-12 relative">
                <div className="absolute right-0 top-1/2 -translate-y-1/2">
                    <button
                        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors"
                        onClick={() => {window.location.href = '/integrations';}}
                    >
                        Connect AI
                    </button>
                </div>
                <h1 className="text-4xl font-bold text-gray-900 text-center">
                    Your AI Playground
                </h1>
            </div>

            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Left Column - Instructions */}
                <div className="bg-white rounded-xl shadow-lg p-6">
                    <h2 className="text-xl font-semibold text-gray-900 mb-4">
                        AI Instructions
                    </h2>

                    {isEditing ? (
                        <div className="space-y-4">
                            <div className="min-h-[500px] max-h-[600px] overflow-y-auto">
                                <textarea
                                    value={instructions}
                                    onChange={(e) => setInstructions(e.target.value)}
                                    className="w-full h-[500px] p-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                                />
                            </div>
                            <button
                                onClick={handleInstructionSave}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <div className="min-h-[500px] max-h-[600px] overflow-y-auto p-4 border border-gray-200 rounded-md">
                                <p className="text-gray-600 whitespace-pre-wrap">
                                    {instructions}
                                </p>
                            </div>
                            <button
                                onClick={() => setIsEditing(true)}
                                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            >
                                Edit
                            </button>
                        </div>
                    )}
                </div>

                {/* Right Column - Chat */}
                <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col">
                    <div className="mb-6">
                        <button
                            onClick={startNewConversation}
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Start new conversation
                        </button>
                    </div>

                    <div className="flex-1 border border-gray-200 rounded-lg p-4 flex flex-col bg-gray-50">
                        <div className="space-y-4 flex-1 overflow-y-auto mb-4 min-h-[500px]">
                            {messages.map((message) => (
                                <div
                                    key={message.id}
                                    className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div
                                        className={`max-w-[80%] p-4 rounded-lg ${
                                            message.sender === 'user'
                                                ? 'bg-blue-400 text-white'
                                                : 'bg-white text-gray-900'
                                        }`}
                                    >
                                        {message.text}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Message Input */}
                        <form onSubmit={handleSendMessage} className="flex gap-2 mt-auto">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type your message..."
                                className="flex-1 p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
                            />
                            <button
                                type="submit"
                                disabled={!newMessage.trim()}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Playground;