import PhotosBloc from './PhotosBloc';
import TitleSection from './TitleSection';
import React from 'react';
const HeroSection = () => {
  return (
    <section className="h-screen relative mobile:h-full">
      <TitleSection />
      <img
        src="/assets/shadowOrange.png"
        alt=""
        className="absolute right-[-9.375rem] bottom-[5.4375rem] hidden desktop:block"
      />
      <img
        src="/assets/heroSection_frame.png"
        alt=""
        className="absolute w-full h-full left-0 bottom-[77px] z-[-1] mobile:hidden block"
      />
      <img
        src="/assets/heroSection_frame_mobile.png"
        alt=""
        className="absolute w-full left-0 z-[-1] hidden mobile:block bottom-[147px]"
      />
      <PhotosBloc />
    </section>
  );
};

export default HeroSection;
