import { IconsTrustedData, TrustedData } from '../../data/TrustedSectionData';
import ReviewBloc from './ReviewBloc';
import React from 'react';
const TrustedSection = () => {
  return (
    <div>
      <div className="pt-[100px] mt-[27px] pb-[123px] bg-custom-gradient-trusted">
        <h2 className="text-offBlack titleSections desktop:text-4xl text-[28px] font-bold leading-[44px] desktop:max-w-[636px] max-w-full tracking-[-1px] text-center mx-auto mb-[45px]">
          Trusted by companies globally from multiple industries
        </h2>
        <div className="grid desktop:grid-cols-3 grid-cols-1 gap-[46px] desktop:px-[196px] tablet:px-[60px] mobile:mx-6 ">
          {TrustedData.map((trusted, index) => (
            <ReviewBloc key={trusted.id} trusted={trusted} index={index} />
          ))}
        </div>
      </div>
      <div className="pb-[123px] bg-white">
        {/*<div className="flex flex-col gap-5 mobile:gap-2 items-center justify-center">*/}
        {/*  <h2 className="text-offBlack titleSections desktop:text-4xl text-[28px]  font-bold leading-[44px] max-w-[636px] tracking-[-1px] text-center mx-auto">*/}
        {/*    Trusted by thousands*/}
        {/*  </h2>*/}
        {/*  <p className="max-w-[450px] text-lg font-normal leading-[30px] text-offBlack opacity-70 text-center">*/}
        {/*    Over 1 million users. Used by thousands of teams, companies and*/}
        {/*    organizations worldwide.*/}
        {/*  </p>*/}
        {/*</div>*/}
        {/*<div className="hidden items-center flex-wrap px-[178px] justify-center mt-[59px] desktop:flex">*/}
        {/*  {IconsTrustedData.map((icon, i) => (*/}
        {/*    <div key={i} dangerouslySetInnerHTML={{ __html: icon.icon }}></div>*/}
        {/*  ))}*/}
        {/*</div>*/}

        {/*<div className="flex desktop:hidden flex-col items-start overflow-scroll sliderScrollbar px-[7px] mt-[59px]">*/}
        {/*  <div className="flex">*/}
        {/*    {IconsTrustedData.slice(*/}
        {/*      0,*/}
        {/*      Math.ceil(IconsTrustedData.length / 2)*/}
        {/*    ).map((icon, i) => (*/}
        {/*      <div*/}
        {/*        key={i}*/}
        {/*        dangerouslySetInnerHTML={{ __html: icon.icon }}></div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*  <div className="flex">*/}
        {/*    {IconsTrustedData.slice(Math.ceil(IconsTrustedData.length / 2)).map(*/}
        {/*      (icon, i) => (*/}
        {/*        <div*/}
        {/*          key={i}*/}
        {/*          dangerouslySetInnerHTML={{ __html: icon.icon }}></div>*/}
        {/*      )*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default TrustedSection;
