import React, { createContext, useState, useEffect } from 'react';
import axios from '../components/AxiosConfig';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeAuth = async () => {
            const storedUser = localStorage.getItem('user');
            const token = localStorage.getItem('token');

            if (storedUser && token) {
                try {
                    const parsedUser = JSON.parse(storedUser);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    const response = await axios.get('/api/sessions/validate_token');
                    if (response.data.success) {
                        setUser(parsedUser);
                    } else {
                        throw new Error('Invalid token');
                    }
                } catch (error) {
                    console.error('Failed to validate token during initialization:', error);
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                }
            }
            setLoading(false); // Initialization complete
        };

        initializeAuth();
    }, []);

    const login = async (email, password) => {
        try {
            const response = await axios.post('/users/sign_in', { email, password });
            const { user, token } = response.data;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setUser(user);
        } catch (error) {
            console.error('Login failed:', error.response?.data?.error || 'An error occurred');
            throw error;
        }
    };

    const logout = async () => {
        try {
            await axios.delete('/users/sign_out');
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setUser(null);
        } catch (error) {
            console.error('Logout failed:', error);
            throw error;
        }
    };

    if (loading) {
        // Show a loading indicator while initializing authentication state
        return <div></div>; // Replace with a spinner or custom loader if needed
    }

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
