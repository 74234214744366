import React from 'react';

const InstagramSuccess = () => {
    return (
        <div className="container">
            <header>
                <h1>LoveItAI</h1>
            </header>
            <main>

                <div className="intro">
                    <div className="title">
                        Instagram Connection Success
                    </div>
                    <img src="/images/pic.png" alt="Get Started" className="get-started-image" />

                    <p className="main-paragraph">
                        Your Instagram was connected successfully!
                    </p>

                </div>
            </main>
            <footer>
                <p>&copy; 2024 LoveItAI. All rights reserved.</p>
            </footer>
        </div>

    );
};

export default InstagramSuccess;