import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
const ReviewBloc = ({ trusted, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current); // Unobserve after triggering once
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`bg-white ${
        isVisible ? 'animate-fadeIn' : ''
      } desktop:px-[20px] px-4 py-[30px] w-full h-full mobile:min-h-full mobile:w-full flex flex-col justify-between desktop:gap-[31px] gap-[26px] rounded-[10px] ${
        index === 1 ? 'shadow-custom-shadow-trusted' : ''
      } `}>
      <p className="text-offBlack text-center desktop:px-[13px] desktop:text-lg text-[15.91px]">
        {trusted.review}
      </p>
      <div className="flex flex-col gap-1 text-center">
        <p className="text-offBlack text-base font-semibold leading-7">
          {trusted.name}
        </p>
        <p className="text-[#52525B] desktop:text-base font-normal leading-[26px] text-sm">
          {trusted.slug}
        </p>
      </div>
    </div>
  );
};

ReviewBloc.propTypes = {
  trusted: PropTypes.shape({
    review: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
};

export default ReviewBloc;
