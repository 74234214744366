import ChatComponent from './ChatComponent';
import IntroComponent from './IntroComponent';
import PropTypes from 'prop-types';
import React from 'react';
const RightContent = ({ demoId }) => {
  return (
    <div className="col-span-3 pr-[9.5rem] tablet:pr-[60px] mobile:px-4">
      <h1 className="font-bold text-5xl leading-[80px] text-offBlack pl-4 mt-7 mobile:hidden">
        How does it work?
      </h1>

      <div className="flex flex-col gap-20">
        <IntroComponent demoId={demoId} />
        <ChatComponent demoId={demoId} />
      </div>
    </div>
  );
};

RightContent.propTypes = {
  demoId: PropTypes.string,
};

export default RightContent;
