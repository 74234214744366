import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const PrivateRoute = ({ element: Component, ...rest }) => {
    const { user } = useContext(AuthContext);

    if (!user) {
        // Redirect to the sign-in page if not authenticated
        return <Navigate to="/sign_in" />;
    }

    return <Route {...rest} element={Component} />;
};

export default PrivateRoute;
