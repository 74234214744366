import { leftData } from '../../data/Demos/LeftSideData';
import ProfileComponent from '../shared/ProfileComponent';
import PropTypes from 'prop-types';
import React from 'react';
const SideBareLeftMobile = ({ setDemoId, demoId }) => {
  return (
    <>
      <h1 className="font-bold text-[28px] leading-[120%] tracking-[-0.28px] text-offBlack pl-4 my-7 text-cente hidden mobile:block">
        How does it work?
      </h1>
      <div className="h-full w-full hidden mobile:flex items-center overflow-scroll gap-4 sliderScrollbar pl-4 ">
        {leftData.map((data, i) => (
          <ProfileComponent
            key={i}
            data={data}
            sidebar={true}
            ismobile={true}
            setDemoId={setDemoId}
            demoId={demoId}
          />
        ))}
      </div>
    </>
  );
};

SideBareLeftMobile.propTypes = {
  setDemoId: PropTypes.func,
  demoId: PropTypes.string,
};

export default SideBareLeftMobile;
