import ChatContent from './ChatContent';
import PropTypes from 'prop-types';
import React from 'react';
const ChatComponent = ({ demoId }) => {
    const handleBuildYourOwn = (e) => {
        console.log('click');
        e.preventDefault(); // Prevent any default behavior
        window.location.href = '/join';
    };
  return (
    <div className="mobile:mb-28">
      <h2 id="try" className="title_blocs">Try it yourself</h2>
      <ChatContent demoId={demoId} />
      <button onClick={handleBuildYourOwn} className="cta_btn text-white w-full bg-background-button mb-11 mobile:hidden">
        Build Your Own For Free
      </button>
    </div>
  );
};

ChatComponent.propTypes = {
  demoId: PropTypes.string,
};

export default ChatComponent;
