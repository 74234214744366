import React from 'react';
import PropTypes from 'prop-types';

const BlocBrand = ({ brand }) => {
  console.log(brand);

  return (
    <div className="flex-1 flex flex-col desktop:flex-row items-start gap-[30px] mt-[56px]">
      <div dangerouslySetInnerHTML={{ __html: brand.icon }}></div>
      <div className="flex flex-col gap-[15px]">
        <h3 className="text-offBlack text-lg font-bold leading-[26px] titleSections">
          {brand.title}
        </h3>
        <p className="text-offBlack opacity-70 text-base font-normal leading-6">
          {brand.intro}
        </p>
      </div>
    </div>
  );
};

BlocBrand.propTypes = {
  brand: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
  }).isRequired,
};

export default BlocBrand;
