import React from 'react';
import BrandSections from '../components/Brand/BrandSections';
import ContentSection from '../components/Content/ContentSection';
import FooterSection from '../components/Footer/FooterSection';
import HeroSection from '../components/Hero/HeroSection';
import TrustedSection from '../components/Trusted/TrustedSection';

const HomePage = () => {
    return (
        <div className="mx-auto overflow-x-hidden">
            <HeroSection />
            <ContentSection />
            <BrandSections />
            <TrustedSection />
            <div className="w-full h-[228px] bg-white"></div>
            <FooterSection />
        </div>
    );
};

export default HomePage;
