// src/components/WaitlistSignups.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const WaitlistSignups = () => {
    const [signups, setSignups] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        axios.get('/api/waitlist_signups')
            .then(response => {
                setSignups(response.data);
            })
            .catch(error => console.error('Error fetching waitlist signups:', error));
    }, []);

    const sendInviteEmail = (id) => {
        axios.post(`/api/waitlist_signups/${id}/send_invite`)
            .then(response => {
                setMessage(`Invite email sent to ${response.data.email}!`);
                setTimeout(() => setMessage(''), 3000); // Clear the message after 3 seconds
            })
            .catch(error => {
                console.error('Error sending invite email:', error);
                setMessage('Failed to send invite email. Please try again.');
                setTimeout(() => setMessage(''), 3000); // Clear the message after 3 seconds
            });
    };

    return (
        <div className="container mx-auto mt-10 p-5 bg-gray-100 rounded shadow-md mt-20">
            <h1 className="text-2xl font-bold mb-4 text-center">Waitlist Signups</h1>
            {message && (
                <div className="mb-4 p-4 text-center bg-green-500 rounded">
                    {message}
                </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {signups.map(signup => (
                    <div
                        key={signup.id}
                        className="p-4 bg-white shadow-md rounded border border-gray-200 flex flex-col justify-between"
                    >
                        <div>
                            <h2 className="font-semibold text-lg">{signup.email}</h2>
                            <p className="text-gray-600 text-sm">Website: {signup.website}</p>
                        </div>
                        <button
                            onClick={() => sendInviteEmail(signup.id)}
                            className="mt-4 p-2 bg-blue-500 hover:bg-blue-600 text-white rounded transition-all"
                        >
                            Send Invite Email
                        </button>
                    </div>
                ))}
            </div>
            {signups.length === 0 && (
                <p className="text-center text-gray-500 mt-4">No waitlist signups found.</p>
            )}
        </div>
    );
};

export default WaitlistSignups;
