import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';

const SignOutButton = () => {
    const { logout } = useContext(AuthContext);
    const handleSignOut = () => {
        fetch('/users/sign_out', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
        }).then(response => {
            if (response.ok) {
                logout();
                window.location.href = '/'; // Redirect to homepage or wherever
            }
        });
    };

    return (
        <button onClick={handleSignOut} className="logout-button">
            Sign Out
        </button>
    );
};

export default SignOutButton;
