import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import React from 'react';
const BlocTextImage = ({ bloc, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current); // Unobserve after triggering once
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);
  return (
    <div
      ref={ref}
      className={`flex desktop:items-center desktop:px-[144px] w-full h-full tablet:flex-col mobile:flex-col items-start px-5 desktop:gap-[160px] gap-[26px] ${
        bloc.isLeft
          ? isVisible
            ? 'animate-slideInLeft'
            : ''
          : isVisible
          ? 'desktop:flex-row-reverse animate-slideInRight'
          : 'desktop:flex-row-reverse'
      } ${index === 0 ? 'mt-[136px]' : 'mt-[70px]'} `}>
      <img src={bloc.img} alt="" className=" w-full h-full flex-1" />
      <div className="flex flex-col gap-[25px] mobile:gap-2 flex-1 desktop:py-[160px] desktop:pr-0 px-[12px] py-[26px]">
        <h2 className="desktop:max-w-[451px] max-w-full desktop:text-4xl text-2xl font-bold desktop:leading-[44px] leading-[120%] tracking-[-1px] text-offBlack text-wrap">
          {bloc.title}
        </h2>
        <p className="desktop:max-w-[451px] max-w-full text-base font-normal leading-[24px] text-offBlack opacity-70">
          {bloc.intro}
        </p>
      </div>
    </div>
  );
};
BlocTextImage.propTypes = {
  bloc: PropTypes.shape({
    isLeft: PropTypes.bool,
    img: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default BlocTextImage;
