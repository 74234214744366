import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

const ConversationList = () => {
    const { userId } = useParams();
    const [conversations, setConversations] = useState([]);

    useEffect(() => {
        axios.get(`/api/users/${userId}/conversations`)
            .then(response => {
                setConversations(response.data);
            })
            .catch(error => console.error('Error fetching conversations:', error));
    }, [userId]);

    const conversationsFromUsMoreThanFiveMinutes = conversations.filter(conversation =>
        conversation.last_message_from_us && conversation.more_than_five_minutes
    );

    const otherConversations = conversations.filter(conversation =>
        !conversation.last_message_from_us || !conversation.more_than_five_minutes
    );

    return (
        <div className="mt-20">
            <h1>Conversations</h1>

            <h2>Last message from us and more than 5 minutes ago (requires follow up)</h2>
            <ul>
                {conversationsFromUsMoreThanFiveMinutes.map(conversation => (
                    <li key={conversation.id}>
                        <Link to={`/admin/users/${userId}/conversations/${conversation.id}/messages`}>
                            Conversation {conversation.id} {conversation.instagram_handle} last message time: {conversation.last_message_time}
                        </Link>
                    </li>
                ))}
            </ul>

            <h2>Other Conversations</h2>
            <ul>
                {otherConversations.map(conversation => (
                    <li key={conversation.id}>
                        <Link to={`/admin/users/${userId}/conversations/${conversation.id}/messages`}>
                            Conversation {conversation.id} {conversation.instagram_handle} last message time: {conversation.last_message_time}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ConversationList;
