// src/mixpanel.js
import mixpanel from 'mixpanel-browser';

export const initMixpanel = () => {
    mixpanel.init('a34f8d2b0695fcecabf07afb48abb70d', {
        debug: true, // Enable debug mode for development
    });
};

export const trackEvent = (eventName, properties = {}) => {
    console.log('mixpaenl track');
    mixpanel.track(eventName, properties);
};

export const identifyUser = (userId, userProperties = {}) => {
    mixpanel.identify(userId);
    mixpanel.people.set(userProperties);
};
