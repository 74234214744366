import React, { useState } from 'react';

const Setup = () => {
    const [purposes, setPurposes] = useState({
        provideInformation: false,
        convertSales: false,
        qualifyLeads: false,
        makeRecommendations: false,
        collectEmail: false
    });

    const [voiceStyle, setVoiceStyle] = useState('');
    const [error, setError] = useState('');

    const handlePurposeChange = (purpose) => {
        setPurposes(prev => ({
            ...prev,
            [purpose]: !prev[purpose]
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation: Check if at least one purpose is selected
        if (!Object.values(purposes).some(Boolean)) {
            setError('Please select at least one purpose.');
            return;
        }

        // Reset error
        setError('');

        // Log the form data (you can replace this with your logic)
        console.log('Form submitted:', { purposes, voiceStyle });

        // Redirect to the /playground page
        window.location.href = '/playground'; // Change location to /playground
    };

    return (
        <div className="mt-20 min-h-screen bg-gradient-to-b from-white to-blue-50 px-4 py-12">
            <div className="max-w-xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900">
                        Customize Your AI Agent
                    </h1>
                </div>

                <div className="bg-white rounded-xl shadow-lg p-6 md:p-8">
                    {error && (
                        <div className="mb-4 p-4 text-red-700 bg-red-100 rounded">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-8">
                        {/* AI Purpose Section */}
                        <div className="space-y-6">
                            <label className="block text-lg font-medium text-gray-900">
                                What would you like your AI to do for you?
                            </label>
                            <div className="space-y-2">
                                {[
                                    { id: 'provideInformation', label: 'Provide information' },
                                    { id: 'convertSales', label: 'Convert customers to sales' },
                                    { id: 'qualifyLeads', label: 'Qualify leads' },
                                    { id: 'makeRecommendations', label: 'Make recommendations' },
                                    { id: 'collectEmail', label: 'Collect email' }
                                ].map(({ id, label }) => (
                                    <div key={id} className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id={id}
                                            checked={purposes[id]}
                                            onChange={() => handlePurposeChange(id)}
                                            className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                        />
                                        <label htmlFor={id} className="ml-3 text-gray-700">
                                            {label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Voice Style Section */}
                        <div className="space-y-6">
                            <label className="block text-lg font-medium text-gray-900">
                                What style of voice would you like your AI to have?
                            </label>
                            <div className="space-y-2">
                                {[
                                    { id: 'friendly', label: 'Friendly and positive' },
                                    { id: 'neutral', label: 'Neutral and professional' }
                                ].map(({ id, label }) => (
                                    <div key={id} className="flex items-center">
                                        <input
                                            type="radio"
                                            id={id}
                                            name="voiceStyle"
                                            value={id}
                                            checked={voiceStyle === id}
                                            onChange={(e) => setVoiceStyle(e.target.value)}
                                            className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        />
                                        <label htmlFor={id} className="ml-3 text-gray-700">
                                            {label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                        >
                            Continue
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Setup;
