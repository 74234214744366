import PropTypes from 'prop-types';
import React from 'react';
const ProfileComponent = ({ data, sidebar, ismobile, setDemoId, demoId }) => {
  return (
    <div
      onClick={() => setDemoId(data.id)}
      className={`flex items-center justify-center cursor-pointer ${
        ismobile ? 'border border-Smoke rounded-2xl bg-white' : ''
      } ${sidebar ? 'group hover:bg-white min-w-[288px]' : ''} ${
        demoId === data.id ? 'bg-white' : ''
      } p-4 gap-2`}>
      <img
        src={data.profileImage}
        alt=""
        className=" w-[48px] h-[48px] rounded-full "
      />
      <div
        className={`flex flex-col w-[calc(100%-48px)] ${ismobile ? '' : ''}`}>
        <p
          className={`font-bold text-offBlack text-[13px] leading-6  whitespace-nowrap ${
            sidebar ? 'group-hover:text-blue-500' : 'opacity-70'
          } ${demoId === data.id ? 'text-blue-500' : ''}`}>
          {data.title}
        </p>
        <p
          className={`font-normal text-offBlack text-[13px] leading-6 whitespace-nowrap ${
            sidebar ? 'group-hover:text-blue-500' : 'opacity-70'
          } ${demoId === data.id ? 'text-blue-500' : ''}`}>
          {data.subtitle}
        </p>
      </div>
    </div>
  );
};

ProfileComponent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    profileImage: PropTypes.string,
  }),
  sidebar: PropTypes.bool,
  ismobile: PropTypes.bool,
  setDemoId: PropTypes.func,
  demoId: PropTypes.string,
};

export default ProfileComponent;
