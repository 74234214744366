import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const AdminRoute = () => {
    const { user } = useContext(AuthContext);
    console.log("ADMIN ROUTE CHECKING USER");
    console.log(user);
    if (!user) {
        // Redirect to sign-in if not authenticated
        return <Navigate to="/sign_in" />;
    }

    if (!user.admin) {
        // Redirect to a "not authorized" page or home if not an admin
        return <Navigate to="/" />;
    }

    // Render the protected component if the user is an admin
    return <Outlet />;
};

export default AdminRoute;
