import { BrandData } from '../../data/BrandSectionData';
import BlocBrand from './BlocBrand';
import React from 'react';
const BrandSections = () => {
  return (
    <div className="desktop:mt-[111px] pt-[82px] pb-[67px]">
      <h2 className="text-offBlack titleSections desktop:text-4xl desktop:w-[639px] text-[28px] font-bold leading-[44px] dekstop:max-w-[577px] max-w-full px-8 tracking-[-1px] text-center mx-auto">
        AI with your Brand Intelligence and Personality Built Right In
      </h2>
      <div className="flex dekstop:items-center items-start flex-col desktop:flex-row desktop:px-[114px] px-[33px] gap-9">
        {BrandData.map((brand) => (
          <BlocBrand key={brand.id} brand={brand} />
        ))}
      </div>
    </div>
  );
};

export default BrandSections;
