// src/api/axiosConfig.js
import axios from 'axios';

// Function to retrieve the CSRF token from meta tags
function getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
}

// Create an Axios instance
const axiosInstance = axios.create({
     // Set your API base URL here
    withCredentials: true, // If your API requires sending cookies
});

// Set CSRF token as a default header
axiosInstance.defaults.headers.common['X-CSRF-Token'] = getCsrfToken();

// Add a request interceptor
axiosInstance.interceptors.request.use(config => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
        config.headers['X-CSRF-Token'] = csrfToken;
    }
    return config;
}, error => {
    // Do something with request error
    return Promise.reject(error);
});

// Optionally, add a response interceptor
axiosInstance.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export default axiosInstance;
