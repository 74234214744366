import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../AxiosConfig';

const InviteAccountCreation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [identifier, setIdentifier] = useState(null);
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [errors, setErrors] = useState([]); // Array to store backend errors
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Extract identifier from query string
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('identifier');
        setIdentifier(id);
    }, [location]);

    // Fetch waitlist signup details
    useEffect(() => {
        if (!identifier) {
            return;
        }

        const fetchWaitlistSignup = async () => {
            try {
                const response = await axios.get(`/api/waitlist_signups/${identifier}`);
                const { email, website } = response.data;
                setEmail(email || '');
                setWebsite(website || '');
            } catch (error) {
                setErrors(['Failed to fetch waitlist details. Please try again.']);
            }
        };

        fetchWaitlistSignup();
    }, [identifier]);

    const validateForm = () => {
        const localErrors = [];
        if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) {
            localErrors.push('Please enter a valid email address.');
        }
        if (!website.trim() || !/^https?:\/\/[^\s/$.?#].[^\s]*$/.test(website)) {
            localErrors.push('Please enter a valid website URL.');
        }
        if (!password.trim() || password.length < 8) {
            localErrors.push('Password must be at least 8 characters.');
        }
        if (password !== passwordConfirmation) {
            localErrors.push('Passwords do not match.');
        }
        setErrors(localErrors);
        return localErrors.length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);

        try {
            const payload = { email, website, password, password_confirmation: passwordConfirmation };
            const response = await axios.post(`/api/waitlist_signups/${identifier}/create_account`, payload);

            const { token, user } = response.data; // Extract token and user
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));

            navigate('/setup'); // Redirect on success
        } catch (error) {
            if (error.response?.data?.errors) {
                setErrors(error.response.data.errors); // Backend validation errors
            } else {
                setErrors(['An unexpected error occurred. Please try again.']);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="mt-20 min-h-screen bg-gradient-to-b from-white to-blue-50 px-4 py-12">
            <div className="max-w-xl mx-auto">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        LoveItAI Get Started
                    </h1>
                    <p className="text-lg text-gray-600">
                        Create your account and let us build your AI knowledge base.
                    </p>
                </div>

                <div className="bg-white rounded-xl shadow-lg p-6 md:p-8">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Display Errors */}
                        {errors.length > 0 && (
                            <div className="bg-red-100 text-red-700 px-4 py-3 rounded">
                                <ul className="list-disc pl-5 space-y-1">
                                    {errors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* Email */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                placeholder="you@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                                disabled={isSubmitting}
                            />
                        </div>

                        {/* Website */}
                        <div>
                            <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-2">
                                Website URL
                            </label>
                            <input
                                type="url"
                                id="website"
                                name="website"
                                required
                                placeholder="https://example.com"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                className="block w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                                disabled={isSubmitting}
                            />
                        </div>

                        {/* Password */}
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                required
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="block w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                                disabled={isSubmitting}
                            />
                        </div>

                        {/* Password Confirmation */}
                        <div>
                            <label
                                htmlFor="passwordConfirmation"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                required
                                placeholder="Confirm your password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                className="block w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                                disabled={isSubmitting}
                            />
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                        >
                            {isSubmitting ? (
                                <div className="flex items-center">
                                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2" />
                                    Processing...
                                </div>
                            ) : (
                                'Create Account'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default InviteAccountCreation;
