import React, { useState, useEffect, useRef } from 'react';
import { createConsumer } from "@rails/actioncable";

const DEMO_TYPES = {
    GENERAL: { id: 'general', name: 'General Assistant', description: 'General purpose AI assistant' },
    CODING: { id: 'coding', name: 'Code Assistant', description: 'Helps with programming and technical questions' },
    MATH: { id: 'math', name: 'Math Tutor', description: 'Assists with mathematical problems and concepts' },
    WRITING: { id: 'writing', name: 'Writing Helper', description: 'Aids in writing and composition' },
    SCIENCE: { id: 'science', name: 'Science Expert', description: 'Explains scientific concepts and theories' },
    BUSINESS: { id: 'business', name: 'Business Advisor', description: 'Helps with business and strategy questions' }
};

const DemoSelector = ({ currentDemo, onSelect }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        {Object.values(DEMO_TYPES).map((demo) => (
            <button
                key={demo.id}
                onClick={() => onSelect(demo.id)}
                className={`p-4 rounded-lg border-2 transition-all ${
                    currentDemo === demo.id
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
                }`}
            >
                <h3 className="font-semibold text-lg mb-1">{demo.name}</h3>
                <p className="text-sm text-gray-600">{demo.description}</p>
            </button>
        ))}
    </div>
);

const ChatWindow = ({ messages, isTyping, messagesEndRef }) => (
    <div className="h-[60vh] overflow-y-auto border border-gray-300 rounded-lg mb-4 p-4">
        {messages.map((msg) => (
            <div
                key={msg.id}
                className={`mb-4 flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
            >
                <div
                    className={`max-w-[80%] p-3 rounded-lg ${
                        msg.role === 'user'
                            ? 'bg-blue-500 text-white'
                            : msg.error
                                ? 'bg-red-100 text-red-700'
                                : 'bg-gray-100 text-gray-900'
                    }`}
                >
                    <div className="text-sm whitespace-pre-wrap">{msg.text}</div>
                    <div className="text-xs mt-1 opacity-75">
                        {new Date(msg.timestamp).toLocaleTimeString()}
                    </div>
                </div>
            </div>
        ))}
        {isTyping && (
            <div className="flex justify-start mb-4">
                <div className="bg-gray-100 text-gray-900 p-3 rounded-lg">
                    <div className="flex space-x-2">
                        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
                        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                    </div>
                </div>
            </div>
        )}
        <div ref={messagesEndRef} />
    </div>
);

const Demo = () => {
    const [currentDemo, setCurrentDemo] = useState(DEMO_TYPES.GENERAL.id);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const channelRef = useRef(null);
    const messagesEndRef = useRef(null);
    const clientIdRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const connectToChannel = () => {
        if (channelRef.current) {
            channelRef.current.unsubscribe();
        }

        setIsConnected(false);
        clientIdRef.current = `${currentDemo}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

        const cable = createConsumer(`${window.location.protocol}//${window.location.host}/cable`); // Use relative URL



        channelRef.current = cable.subscriptions.create(
            {
                channel: "DemoChannel",
                client_id: clientIdRef.current,
                demo_type: currentDemo
            },
            {
                connected() {
                    console.log(`Connected to ${currentDemo} demo chat`);
                    setIsConnected(true);
                    setMessages([]); // Clear messages for new demo
                },
                disconnected() {
                    console.log('Disconnected from demo chat');
                    setIsConnected(false);
                },
                received(data) {
                    console.log('Received:', data);
                    setIsTyping(false);

                    if (data.type === 'history') {
                        setMessages(data.messages.map(msg => ({
                            ...msg,
                            id: msg.message_id
                        })));
                    } else {
                        setMessages((prev) => [...prev, {
                            text: data.message,
                            role: data.role,
                            timestamp: data.timestamp,
                            error: data.error,
                            id: data.message_id || Date.now()
                        }]);
                    }
                }
            }
        );
    };

    useEffect(() => {
        connectToChannel();

        return () => {
            if (channelRef.current) {
                channelRef.current.unsubscribe();
                channelRef.current = null;
            }
        };
    }, [currentDemo]);

    const handleDemoChange = (demoId) => {
        setCurrentDemo(demoId);
    };

    const sendMessage = (e) => {
        e.preventDefault();
        if (!input.trim() || !channelRef.current) return;

        // Add user message immediately
        setMessages((prev) => [...prev, {
            text: input.trim(),
            role: 'user',
            timestamp: new Date(),
            id: Date.now()
        }]);

        // Show typing indicator
        setIsTyping(true);

        try {
            channelRef.current.perform('speak', {
                message: input.trim(),
                client_id: clientIdRef.current,
                demo_type: currentDemo
            });
            setInput('');
        } catch (error) {
            console.error('Error sending message:', error);
            setIsTyping(false);
        }
    };

    return (
        <div className="p-4 max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">AI Demo Chat</h1>

            <DemoSelector
                currentDemo={currentDemo}
                onSelect={handleDemoChange}
            />

            <div className={`mb-2 ${isConnected ? 'text-green-600' : 'text-red-600'}`}>
                Status: {isConnected ? 'Connected' : 'Connecting...'}
            </div>

            <ChatWindow
                messages={messages}
                isTyping={isTyping}
                messagesEndRef={messagesEndRef}
            />

            <form onSubmit={sendMessage} className="flex gap-2">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={`Ask the ${DEMO_TYPES[currentDemo.toUpperCase()].name} something...`}
                    className="flex-1 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    disabled={!isConnected}
                />
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!isConnected || isTyping || !input.trim()}
                >
                    {isTyping ? 'Sending...' : 'Send'}
                </button>
            </form>
        </div>
    );
};

export default Demo;