import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackEvent } from './mixpanel';

const PageViewTracker = () => {
    const location = useLocation();

    useEffect(() => {
        trackEvent('Page Viewed', { path: location.pathname });
    }, [location]);

    return null;
};

export default PageViewTracker;