export const contentData = [
  {
    id: 1,
    img: './assets/firstItem.png',
    title: 'Provide Rich Product Information',
    intro:
      'AI responds with high quality content including images, videos and reviews from your website',
    isLeft: true,
  },
  {
    id: 2,
    img: './assets/secondItem.png',
    title: 'Make Personalized Recommendations',
    intro:
      'AI asks questions and then provides recommendations tailored to their needs and interests',
    isLeft: false,
  },
  {
    id: 3,
    img: './assets/thirdItem.png',
    title: 'Collect Emails for Future Follow Ups',
    intro:
      'Seamlessly ask for contact information to keep customers engaged with newsletters, updates and promotions',
    isLeft: true,
  },
  {
    id: 4,
    img: './assets/fouItem.png',
    title: 'Convert Sales with Promotions',
    intro:
      'Use discounts, promotions, and personalized recommendation to guide customers to checkout.',
    isLeft: false,
  },
];
