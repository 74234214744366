export const leftSideData = [
  {
    img: '/assets/profileOneDemo.jpeg',
    title: 'Fitness Studio',
    intro: 'Class descriptions & scheduling',
  },
  {
    img: '/assets/profileOneDemo.jpeg',
    title: 'Dress Store',
    intro: 'Promotions & discounts',
  },
  {
    img: '/assets/profileOneDemo.jpeg',
    title: 'Pet Training',
    intro: 'Information & Logistics',
  },
  {
    img: '/assets/profileOneDemo.jpeg',
    title: 'Nutrition Store',
    intro: 'Qualify Ad Leads',
  },
  {
    img: '/assets/profileOneDemo.jpeg',
    title: 'Luxury Handbag',
    intro: 'Pricing & Reviews',
  },
  {
    img: '/assets/profileOneDemo.jpeg',
    title: 'Local Brewery',
    intro: 'Seasonal flavors',
  },
];

export const leftData = [
  {
    title: 'Fitness Studio',
    subtitle: 'Class Descriptions & Scheduling',
    profileImage: '/assets/GroupFitness.png',
    description:
      'Move & Groove Studio is a lively space where individuals of all skill levels can enjoy dance, yoga, and fitness classes. Our welcoming community fosters confidence and connection through energizing movement.',
    videoUrl:
      'https://loveit-videos.s3.us-east-2.amazonaws.com/Fitness+Studio.mp4',
    id: 'fitness',
    historyMessage: [
      {
        id: 'bot',
        message: 'Hello! How can I help you today? - Fitness Studio',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
    ],
  },
  {
    title: 'Boutique Dress Shop',
    subtitle: 'Promotions & Discounts',
    profileImage: '/assets/GroupDress.png',
    description:
      'Elysian Dresses offers a curated selection of stylish dresses for every occasion. We empower individuals to express their unique style and find the perfect fit that makes them feel fabulous.',
    videoUrl:
      'https://loveit-videos.s3.us-east-2.amazonaws.com/Dress+Store.mp4',
    id: 'dress',
    historyMessage: [
      {
        id: 'bot',
        message: 'Hello! How can I help you today? - Boutique Dress Shop',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
    ],
  },
  {
    title: 'Pet Training',
    subtitle: 'Information & Logistics',
    profileImage: '/assets/GroupPet.png',
    description:
      'Pawsitive Training Academy transforms pets into well-behaved companions using positive reinforcement techniques. Our expert trainers provide tailored programs for dogs of all ages to promote happy relationships.',
    videoUrl:
      'https://loveit-videos.s3.us-east-2.amazonaws.com/Pet+Training+with+Voice.mp4',
    id: 'pet',
    historyMessage: [
      {
        id: 'bot',
        message: 'Hello! How can I help you today? - Pet Training',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
    ],
  },
  {
    title: 'Nutrition Store',
    subtitle: 'Qualify Ad Leads',
    profileImage: '/assets/GroupNutrition.png',
    description:
      'Nourish & Flourish Market is your destination for high-quality supplements and organic snacks. We empower customers on their wellness journey with a diverse selection of nourishing products.',
    videoUrl:
      'https://loveit-videos.s3.us-east-2.amazonaws.com/Nutrition+Store+with+Voice.mp4',
    id: 'nutrition',
    historyMessage: [
      {
        id: 'bot',
        message: 'Hello! How can I help you today? - Nutrition Store',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
    ],
  },
  {
    title: 'Luxury Handbags',
    subtitle: 'Pricing & Reviews',
    profileImage: '/assets/GroupLux.png',
    description:
      'Chic Couture Handbags features a stunning collection of luxury handbags from renowned designers. Our curated selection combines timeless elegance with contemporary style.',
    videoUrl:
      'https://loveit-videos.s3.us-east-2.amazonaws.com/Luxury+Handbag+with+Voice.mp4',
    id: 'handbag',
    historyMessage: [
      {
        id: 'bot',
        message: 'Hello! How can I help you today? - Luxury Handbags',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
    ],
  },
  {
    title: 'Local Brewery',
    subtitle: 'Season Flavors',
    profileImage: '/assets/GroupDrink.png',
    description:
      'Crafted Brews Brewery is a local gem offering a variety of handcrafted beers brewed on-site. Our diverse lineup caters to all tastes, making every visit a celebration of flavor and community.',
    videoUrl:
      'https://loveit-videos.s3.us-east-2.amazonaws.com/Local+Brewery+With+Voice.mp4',
    id: 'brewery',
    historyMessage: [
      {
        id: 'bot',
        message: 'Hello! How can I help you today? - Local Brewery',
      },
      {
        id: 'user',
        message: 'When do your next classes start?',
      },
      {
        id: 'bot',
        message: 'Follow these Steps...',
      },
    ],
  },
];
