export const FooterData = {
  logo: `<svg xmlns="http://www.w3.org/2000/svg" width="214" height="41" viewBox="0 0 214 41" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M195.86 34.7838C205.465 34.7838 213.252 26.9972 213.252 17.3919C213.252 7.78662 205.465 0 195.86 0C186.254 0 178.468 7.78662 178.468 17.3919C178.468 23.2794 181.393 28.4837 185.87 31.6303C185.747 31.8344 185.639 32.0486 185.54 32.2656C184.113 35.3917 179.287 39.9563 175.869 40.0693C180.001 40.0336 193.107 39.1527 195.374 34.7772C195.536 34.7816 195.697 34.7838 195.86 34.7838Z" fill="white"/>
  <path d="M195.653 10.9682C195.654 10.9678 195.654 10.9673 195.655 10.9669L195.653 10.9682ZM195.653 10.9682L195.86 11.1744L196.227 11.542L195.86 11.1745L195.653 10.9684C195.653 10.9683 195.653 10.9683 195.653 10.9682ZM204.757 20.0719C207.271 17.5576 207.272 13.4813 204.757 10.9681C202.311 8.52306 198.385 8.45539 195.86 10.7708C193.333 8.45572 189.408 8.52133 186.962 10.9684C184.449 13.4824 184.447 17.5586 186.962 20.0721L194.684 27.7943C195.334 28.4433 196.386 28.4433 197.035 27.7943L204.757 20.0719Z" fill="#FE3155"/>
  <path d="M195.653 10.9682C195.654 10.9678 195.654 10.9673 195.655 10.9669L195.653 10.9682ZM195.653 10.9682L195.86 11.1744L196.227 11.542L195.86 11.1745L195.653 10.9684C195.653 10.9683 195.653 10.9683 195.653 10.9682ZM204.757 20.0719C207.271 17.5576 207.272 13.4813 204.757 10.9681C202.311 8.52306 198.385 8.45539 195.86 10.7708C193.333 8.45572 189.408 8.52133 186.962 10.9684C184.449 13.4824 184.447 17.5586 186.962 20.0721L194.684 27.7943C195.334 28.4433 196.386 28.4433 197.035 27.7943L204.757 20.0719Z" fill="url(#paint0_linear_513_1211)"/>
  <path d="M195.653 10.9682C195.654 10.9678 195.654 10.9673 195.655 10.9669L195.653 10.9682ZM195.653 10.9682L195.86 11.1744L196.227 11.542L195.86 11.1745L195.653 10.9684C195.653 10.9683 195.653 10.9683 195.653 10.9682ZM204.757 20.0719C207.271 17.5576 207.272 13.4813 204.757 10.9681C202.311 8.52306 198.385 8.45539 195.86 10.7708C193.333 8.45572 189.408 8.52133 186.962 10.9684C184.449 13.4824 184.447 17.5586 186.962 20.0721L194.684 27.7943C195.334 28.4433 196.386 28.4433 197.035 27.7943L204.757 20.0719Z" stroke="#141447" stroke-width="1.62324"/>
  <path d="M194.7 12.6385C193.85 11.5177 189.869 10.7061 188.091 12.7545" stroke="white" stroke-width="0.850271" stroke-linecap="round"/>
  <path d="M187.396 13.9143C186.958 14.6357 186.546 15.8467 187.396 17.3154" stroke="white" stroke-width="0.850271" stroke-linecap="round"/>
  <path d="M211.397 20.0972C210.817 22.4934 210.276 24.8896 206.063 28.7931" stroke="#242468" stroke-width="0.850271" stroke-linecap="round"/>
  <path d="M205.174 29.4893C203.087 31.1512 202.353 31.3444 200.768 32.0787" stroke="#242468" stroke-width="0.850271" stroke-linecap="round"/>
  <path d="M169.863 40.0609V2.50464H176.945V40.0609H169.863Z" fill="white"/>
  <path d="M136.637 40.0609L146.509 2.50464H158.849L168.721 40.0609H161.424L159.385 31.7985H145.972L143.934 40.0609H136.637ZM147.636 25.253H157.722L153.162 6.95774H152.196L147.636 25.253Z" fill="white"/>
  <path d="M128.505 40.0609C126.753 40.0609 125.322 39.5244 124.213 38.4514C123.14 37.3426 122.604 35.8761 122.604 34.0519V19.0294H115.951V13.4496H122.604V5.18726H129.364V13.4496H136.66V19.0294H129.364V32.8716C129.364 33.9446 129.864 34.4811 130.866 34.4811H136.016V40.0609H128.505Z" fill="white"/>
  <path d="M107.304 40.0609V2.50464H114.386V40.0609H107.304Z" fill="white"/>
  <path d="M92.0318 40.8123C89.385 40.8123 87.0422 40.2579 85.0035 39.1491C83.0005 38.0045 81.4267 36.4128 80.2821 34.3741C79.1733 32.2995 78.6189 29.8673 78.6189 27.0774V26.4336C78.6189 23.6437 79.1733 21.2294 80.2821 19.1906C81.3909 17.1161 82.9468 15.5244 84.9498 14.4156C86.9528 13.271 89.2777 12.6987 91.9245 12.6987C94.5356 12.6987 96.8069 13.2889 98.7383 14.4692C100.67 15.6138 102.172 17.2234 103.245 19.2979C104.318 21.3367 104.855 23.7152 104.855 26.4336V28.7406H85.4863C85.5579 30.5648 86.2375 32.0491 87.5251 33.1937C88.8127 34.3383 90.3865 34.9106 92.2465 34.9106C94.1421 34.9106 95.5371 34.4992 96.4313 33.6766C97.3255 32.8539 98.0051 31.9418 98.4701 30.9403L103.996 33.8375C103.495 34.7675 102.762 35.7869 101.796 36.8957C100.867 37.9687 99.6146 38.8987 98.0408 39.6856C96.4671 40.4367 94.4641 40.8123 92.0318 40.8123ZM85.54 23.6973H97.9872C97.8441 22.1593 97.2182 20.9253 96.1094 19.9954C95.0363 19.0654 93.6235 18.6004 91.8709 18.6004C90.0467 18.6004 88.5981 19.0654 87.5251 19.9954C86.4521 20.9253 85.7904 22.1593 85.54 23.6973Z" fill="white"/>
  <path d="M60.2321 40.0615L51.7551 13.4502H58.9445L65.1144 35.1255H66.0802L72.2501 13.4502H79.4395L70.9625 40.0615H60.2321Z" fill="white"/>
  <path d="M38.6021 40.8123C35.9552 40.8123 33.5767 40.2758 31.4664 39.2027C29.3561 38.1297 27.6929 36.5738 26.4767 34.535C25.2606 32.4962 24.6526 30.0461 24.6526 27.1847V26.3263C24.6526 23.4649 25.2606 21.0148 26.4767 18.976C27.6929 16.9372 29.3561 15.3813 31.4664 14.3083C33.5767 13.2352 35.9552 12.6987 38.6021 12.6987C41.2489 12.6987 43.6274 13.2352 45.7377 14.3083C47.848 15.3813 49.5113 16.9372 50.7274 18.976C51.9435 21.0148 52.5515 23.4649 52.5515 26.3263V27.1847C52.5515 30.0461 51.9435 32.4962 50.7274 34.535C49.5113 36.5738 47.848 38.1297 45.7377 39.2027C43.6274 40.2758 41.2489 40.8123 38.6021 40.8123ZM38.6021 34.8033C40.6766 34.8033 42.3935 34.1416 43.7526 32.8182C45.1118 31.459 45.7914 29.5275 45.7914 27.0238V26.4872C45.7914 23.9835 45.1118 22.0699 43.7526 20.7465C42.4292 19.3873 40.7124 18.7077 38.6021 18.7077C36.5275 18.7077 34.8107 19.3873 33.4515 20.7465C32.0923 22.0699 31.4127 23.9835 31.4127 26.4872V27.0238C31.4127 29.5275 32.0923 31.459 33.4515 32.8182C34.8107 34.1416 36.5275 34.8033 38.6021 34.8033Z" fill="white"/>
  <path d="M0 40.0609V2.50464H7.08204V33.6227H24.2506V40.0609H0Z" fill="white"/>
  <defs>
    <linearGradient id="paint0_linear_513_1211" x1="185.888" y1="18.858" x2="205.831" y2="18.858" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FF0173"/>
      <stop offset="1" stop-color="#FE6625"/>
    </linearGradient>
  </defs>
</svg>`,
  links: [],
  quickLinks: [
    {
      title: 'Help',
      links: [
        'Terms & Conditions',
        'Privacy Policy',
      ],
    },
    // {
    //   title: 'Resources',
    //   links: [
    //     'Free eBooks',
    //     'Development Tutorial',
    //     'How to Blog',
    //     'Youtube Playlist',
    //   ],
    // },
    // {
    //   title: 'Links',
    //   links: [
    //     'Free eBooks',
    //     'Development Tutorial',
    //     'How to Blog',
    //     'Youtube Playlist',
    //   ],
    // },
  ],
};
