import React, { useContext, useEffect, useState } from 'react';
import InstagramLoginButton from './Manage/InstagramLoginButton';
import SignOutButton from "./SignOutButton";
import { AuthContext } from '../context/AuthContext';

const GetStarted = () => {
    const { user } = useContext(AuthContext);
    return (
        <div className="gs-container">
            <header>
                <h1>LoveItAI</h1>
                {user && <SignOutButton/>}
            </header>
            <main>

                <div className="intro">
                    <div className="title">
                        Get Started
                    </div>
                    <img src="/images/pic.png" alt="Get Started" className="get-started-image" />

                    <p className="main-paragraph">
                        Revolutionize your business with 24/7 AI-enabled marketing and selling directly through social
                        media messages and SMS. Leverage headless commerce to engage customers, answer product
                        questions, and complete transactions without the need for a traditional website or additional
                        workforce. Transform your customer engagement and boost conversion rates effortlessly.
                    </p>
                    <InstagramLoginButton/>
                    <p>
                        By connecting you agree to the <a target="_blank" href={"https://docs.google.com/document/d/1gEbjmi8FFKzcXbNNxw0Cxs8ii1shCaCGx7qjes1aGWY/edit?usp=sharing"}>Terms of Service</a>
                    </p>
                </div>
            </main>
            <footer>
                <p>&copy; 2024 LoveItAI. All rights reserved.</p>
            </footer>
        </div>

    );
};

export default GetStarted;