import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const MessageList = () => {
    const { userId, conversationId } = useParams();
    const [messages, setMessages] = useState([]);
    const [replyText, setReplyText] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isReplying, setIsReplying] = useState(false);
    const [adDetails, setAdDetails] = useState('');

    useEffect(() => {
        fetchMessages();
        const intervalId = setInterval(fetchMessages, 30000); // Auto-refresh every 5 seconds

        return () => clearInterval(intervalId); // Clear interval when component unmounts
    }, [conversationId]);

    const fetchMessages = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/conversations/${conversationId}/messages`);
            setMessages(response.data.messages);  // Assuming `messages` is in `data.messages`
            setAdDetails(response.data.ad_details);  // Assuming `ad_details` is part of the API response
        } catch (error) {
            console.error('Error fetching messages:', error);
            setError('Failed to fetch messages');
        } finally {
            setIsLoading(false);
        }
    };
    const handleReply = async (e) => {
        e.preventDefault();
        if (!replyText.trim()) return;
        setIsReplying(true);
        setError('');
        try {
            await axios.post('/api/send_reply', {
                conversation_id: conversationId,
                reply_text: replyText
            });
            setReplyText('');
            fetchMessages(); // Refetch messages to show the newly sent one
        } catch (err) {
            console.log(err)
            setError(err.response.data.error);
        } finally {
            setIsReplying(false);
        }
    };

    return (
        <div className="mt-20">
            <h1>Messages</h1>
            {adDetails && (
                <div>
                    <h2>Ad Details</h2>
                    <p>{adDetails}</p>
                </div>
            )}
            <ul>
                {messages.map(message => (
                    <li key={message.id}>
                        <p>{message.message_text}</p>
                        <small>Type: {message.message_type}</small><br />
                        <small>Received At: {new Date(message.received_at).toLocaleString()}</small>
                    </li>
                ))}
            </ul>
            <form onSubmit={handleReply}>
                <textarea
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder="Type your reply..."
                    required
                    disabled={isReplying}
                />
                <button type="submit" disabled={isReplying || !replyText.trim()}>
                    {isReplying ? 'Sending...' : 'Send Reply'}
                </button>
            </form>
            {error && <div style={{ color: 'red' }}><b>{error}</b></div>}
            {isLoading && <div>Loading...</div>} {/* Moved loading to the bottom */}
        </div>
    );
};

export default MessageList;
