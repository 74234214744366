import React from 'react';

const InstagramLogin = () => {
    const isDev = window.location.hostname === 'localhost' || window.location.hostname.includes('ngrok');

    const redirectUri = isDev ? "https://0d78-2601-646-8700-e790-549f-7366-7b50-1af7.ngrok-free.app/auth/callback" : "https://www.loveitai.com/auth/callback"
    const clientId = isDev ? "1662640057917236" : "1207036460331201"
    const handleLogin = () => {
        const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish`;
        window.location.href = instagramAuthUrl;
    };

    return <button className="instagram-button" onClick={handleLogin}>Login with Instagram</button>;
};

export default InstagramLogin;