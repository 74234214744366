import React, { useState } from 'react';

const WaitlistSignup = () => {
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('/waitlist_signups', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ waitlist_signup: { email, website } }),
            });

            if (response.ok) {
                setSubmitted(true);
            } else {
                const errorData = await response.json();
                console.error('Signup failed:', errorData.errors);
            }
        } finally {
            setLoading(false);
        }
    };

    if (submitted) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-50 p-4">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                    <div className="text-center">
                        <div className="mx-auto rounded-full bg-green-100 p-3 mb-4 w-12 h-12 flex items-center justify-center">
                            <span className="text-2xl text-green-600">✓</span>
                        </div>
                        <h2 className="text-2xl font-bold text-gray-900 mb-2">
                            You're on the list!
                        </h2>
                        <p className="text-gray-600">
                            Early access will open in phases, and we'll let you know as soon as it's your turn.
                            Stay tuned for updates and exclusive information as we get closer to launch!
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-50 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                <div className="text-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">
                        Join the Waitlist
                    </h2>
                    <p className="text-gray-600">
                        LoveItAI is currently in early release. Join the waitlist to gain early access.
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <input
                            type="email"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Company website"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors"
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                    >
                        {loading ? (
                            <span className="inline-flex items-center justify-center">
                                <span className="animate-spin mr-2">⟳</span>
                                Joining...
                            </span>
                        ) : (
                            'Join Waitlist'
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default WaitlistSignup;