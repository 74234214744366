import { contentData } from '../../data/ContentSectionData';
import BlocTextImage from './BlocTextImage';
import React from 'react';
const ContentSection = () => {
  return (
    <section className="desktop:mt-[200px] mt-[200px] mobile:mt-[126px]">
      <h2 className="text-offBlack titleSections desktop:text-4xl desktop:w-[637px] text-[28px] font-bold leading-[44px] dekstop:max-w-[577px] max-w-full px-8 tracking-[-1px] text-center mx-auto">
        LoveItAI Is There for Your Customer Every Step of the Way
      </h2>
      {contentData.map((bloc, i) => (
        <BlocTextImage key={bloc.id} bloc={bloc} index={i} />
      ))}
    </section>
  );
};

export default ContentSection;
