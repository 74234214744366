import React from 'react';
import { quickLinks } from '../../data/heroSectionData';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const handleGetStarted = () => {
    window.location.href = '/join';
  };

  return (
      <div className="flex items-center justify-between mx-auto fixed bg-white w-full z-[999999] top-0 h-[5rem] shadow-lg">
        <div className="w-full desktop:px-[9.5rem] flex items-center justify-between mx-auto bg-white fixed">
          <div className="flex items-center gap-[3.59375rem] desktop:pl-0 pl-[29px]">
            <a href="/">
              <img
                  src="/assets/logoLoveitAi.png"
                  alt="LoveItAI Logo"
                  className="max-w-[137px]"
              />
            </a>
            <nav className="desktop:flex items-center gap-3 hidden">
              {quickLinks.map((link, i) => (
                  <a
                      href={`/${link.toLowerCase()}`}
                      key={i}
                      className="heeboText hover:text-blue-600 transition-colors"
                  >
                    {link}
                  </a>
              ))}
            </nav>
          </div>
          <div className="flex items-center gap-8">
            <button className="desktop:pr-0 pr-[29px]">
              {/* Login */}
            </button>
            <button
                onClick={handleGetStarted}
                className="bg-background-button desktop:block hidden h-[56px] w-[167px] rounded-[4px] text-white
              hover:opacity-90 transition-opacity active:transform active:scale-95"
            >
              Get Started Free
            </button>
          </div>
        </div>
      </div>
  );
};

export default Header;
