import React from 'react';
const PhotosBloc = () => {
  return (
    <div className="w-full h-[39.131437rem] relative flex gap-14 mobile:gap-0 items-center bottom-0 mobile:bottom-[-87px] tablet:items-start mobile:px-8">
      <div className="w-full h-full relative flex-1 mobile:hidden">
        <img
          src="/assets/EcomFrame.png"
          alt=""
          className="absolute block mobile:hidden max-w-[271.619px] object-contain h-[80px] left-[74px] minDesktop:left-[28px] z-[1000] top-[143px] tablet:top-[48px] tablet:rotate-[8deg] tablet:left-[-39px] "
        />
        <img
          src="/assets/ItemFriendly.png"
          alt=""
          className="absolute mobile:hidden block max-w-[266.66px] h-[222px] left-[66px] minDesktop:left-[26px] z-[1000] bottom-[141px] minDesktop:bottom-[148px] tablet:bottom-[217px] tablet:left-[-30px] object-contain"
        />
        <img
          src="/assets/ProductFrame.png"
          alt=""
          className="absolute mobile:hidden block max-w-[193px] h-[180px] mobile:h-[63px] left-[26px] mobile:left-[-20px] mobile:bottom-[250px] minDesktop:left-[4px] z-[1000] bottom-[-9px] minDesktop:bottom-[-4px] tablet:left-[-34px] tablet:bottom-[51px] object-contain"
        />
        {/* <img
          src="/assets/ProductFrameMobile.png"
          alt=""
          className="absolute mobile:block hidden h-[63px] left-0 bottom-[250px] z-[1000] object-contain mobileHeroSection:bottom-[257px]"
        /> */}
      </div>
      <div
        className="flex-[3] flex items-center justify-center bg-white w-full mobile:w-[13.590125rem] h-full relative z-[1000] shadow-custom-shadow bottom-[-2.9375rem] mobile:bottom-[47px]
        outline-[#FF0173] outline rounded-[1.8889375rem] animate-fadeIn">
        {/* <img
          src="/assets/playIcon.png"
          alt=""
          className="absolute desktop:w-[10.393125rem] desktop:h-[10.393125rem] w-[2.885875rem] h-[2.885875rem] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[1000]"
        /> */}
        <video
          src={`https://loveit-videos.s3.us-east-2.amazonaws.com/LumintSkinCare.mp4`}
          autoPlay
          loop
          muted
          playsInline
          className="absolute max-w-full max-h-full object-cover rounded-[1.8889375rem]"></video>
      </div>
      <div className="w-full flex-1 h-full relative mobile:hidden">
        <img
          src="/assets/ItemRevenue.png"
          alt=""
          className="absolute mobile:hidden block max-w-[332px] h-[254px] right-[25px] minDesktop:right-[-9px] z-[1000] bottom-[202px] mobile:top-[132px] mobile:h-[69px] mobile:right-[-26px] tablet:right-[-100px] tablet:bottom-[283px] object-cover"
        />
        <img
          src="/assets/salesFrame.png"
          alt=""
          className="absolute mobile:hidden block max-w-[180px] h-[255px] right-[111px] minDesktop:right-[87px] z-[1000] bottom-[-34px] tablet:bottom-[56px] tablet:right-[-13px] object-cover"
        />
      </div>
    </div>
  );
};

export default PhotosBloc;
